applicationInsightsModule = (function () {
    return {
        Init: function (connectionString, caseName, participantId, userId, emailAddress) {
            if (!connectionString) {
                return;
            }

            var snippet = {
                config: {
                    connectionString: connectionString,
                    extensionConfig: {
                        AppInsightsCfgSyncPlugin: {
                            cfgUrl: '' //Prevent loading a file from MS CDN - https://github.com/microsoft/ApplicationInsights-JS/blob/main/docs/WebConfig.md#disable-fetching-from-cdn
                        }
                    }
                }
            };
            var init = new Microsoft.ApplicationInsights.ApplicationInsights(snippet);
            appInsights = init.loadAppInsights();

            if (caseName) {
                //Add custom properties to all telemetry - https://learn.microsoft.com/en-us/azure/azure-monitor/app/api-filtering-sampling?tabs=dotnet%2Cjavascriptwebsdkloaderscript#javascript-telemetry-initializers
                appInsights.addTelemetryInitializer(envelope => {
                    envelope.data = envelope.data || {};
                    envelope.data.Case_CF = caseName;
                    envelope.data.PartId_CF = participantId ? participantId.toString() : '';
                    envelope.data.UserId_CF = userId ? userId.toString() : '';
                    envelope.data.Email_CF = emailAddress || '';
                });
            }

            if (userId) {
                appInsights.setAuthenticatedUserContext(userId);
            }

            appInsights.trackPageView();
        },

        InitEventTracking: function () {
            if (!appInsights) {
                return;
            }

            //Track clicking on external links or html elements tagged for tracking as app insight events
            $(document).on('click', "a[href^='http'], [data-ai-event-name]", function () {
                appInsights.trackEvent({
                    name: $(this).data('ai-event-name') || 'outbound-link-click',
                    properties: {
                        Label_CF: $(this).data('ai-event-label') || '',
                        URL_CF: $(this).attr('href') || ''
                    }
                });
            });
        }
    };
})();
